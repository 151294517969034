import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { HttpClient } from '@angular/common/http';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { catchError, map, switchMap, tap } from 'rxjs/operators';
import { BehaviorSubject, interval, of, throwError, timer } from 'rxjs';
import { AppDataService } from '@rosatom/app-data';

@Component({
  selector: 'dp-feedback-form',
  templateUrl: './feedback-form.component.html',
  styleUrls: ['./feedback-form.component.styl']
})
@UntilDestroy()
export class FeedbackFormComponent implements OnInit {
  form: FormGroup;
  @Input() type: string;
  @Input() product: string;
  @Input() colorScheme: 'main' | 'product';
  @Output() success = new EventEmitter();
  @Output() error = new EventEmitter();
  successGenerator$ = new BehaviorSubject(false);
  success$ = this.successGenerator$.pipe(
    switchMap(isSuccess => {
      if (isSuccess) {
        return interval(1000).pipe(
          map(value => {
            if (value < 5)
              return true;

            this.successGenerator$.next(false);
            this.success.emit();
            return false;
          }),
        );
      } else {
        return of(false);
      }
    }),
  );
  errorGenerator$ = new BehaviorSubject(false);
  error$ = this.errorGenerator$.pipe(
    switchMap(isError => {
      if (isError) {
        return interval(1000).pipe(
          map(value => {
            if (value < 5)
              return true;

            this.error.emit();
            this.errorGenerator$.next(false);
            return false;
          }),
        );
      } else {
        return of(false);
      }
    }),
  );
  staticText = this.appDataService.appData.staticText;

  constructor(
    private readonly fb: FormBuilder,
    private readonly http: HttpClient,
    private readonly appDataService: AppDataService,
  ) { }

  ngOnInit(): void {
    this.form = this.fb.group({
      name: [null, Validators.required],
      email: [null, [Validators.required, Validators.email]],
      company: [null],
      phone: [null],
      type: [this.type],
      product: [this.product],
      pdAgreement: [false, Validators.requiredTrue],
    })
  }

  submit() {
    if (!this.form.valid) return;
    const payload = { ...this.form.value };
    delete payload.pdAgreement;

    this.http.post('/api/form', payload)
      .pipe(
        untilDestroyed(this),
        catchError(err => {
          this.errorGenerator$.next(true);

          return throwError(err);
        }),
      )
      .subscribe(() => this.successGenerator$.next(true));
  }

}
