import { Component, OnInit } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { TopPanelShowService } from '../../top-panel-show.service';
import { filter } from 'rxjs/operators';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { getStateTreeData } from '../../utils';
import { RouteTypeEnum } from '../../enums/route-type.enum';

@Component({
  selector: 'dp-rosatom-logo-overlay',
  templateUrl: './rosatom-logo-overlay.component.html',
  styleUrls: ['./rosatom-logo-overlay.component.styl']
})
@UntilDestroy()
export class RosatomLogoOverlayComponent implements OnInit {
  showTopPanel$ = new BehaviorSubject(true);
  showLogo: boolean;

  constructor(
    private readonly router: Router,
    private readonly route: ActivatedRoute,
    private readonly topPanelShowService: TopPanelShowService,
  ) { }

  ngOnInit(): void {
    this.initRouterListener();
    this.topPanelShowService.showTopPanel$
      .pipe(
        untilDestroyed(this),
      )
      .subscribe(this.showTopPanel$);
  }

  private initRouterListener() {
    this.router.events
      .pipe(
        filter((e): e is NavigationEnd => e instanceof NavigationEnd),
        untilDestroyed(this),
      )
      .subscribe(() => this.updateLogo());
  }

  private updateLogo() {
    const routeData = getStateTreeData(this.route.snapshot);
    this.showLogo = routeData.routeType !== RouteTypeEnum.ProductPage && routeData.routeType !== RouteTypeEnum.PrintPage;
  }

}
