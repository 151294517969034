import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class RosatomLogoService {
  private _logoMidText$ = new BehaviorSubject<string | null>(null);
  logoMidText$ = this._logoMidText$.asObservable();

  setLogoMidText(newText: string) {
    this._logoMidText$.next(newText);
  }

  resetLogoMidText() {
    this._logoMidText$.next(null);
  }
}
