import {MathUtils} from "three";

export class AnimationController {

    animationDuration: number;
    targetAnimationTime = 0;
    currentAnimationTime = 0;
    private animationStartTimestamp = 0;

    private readonly easingFunction: (t:number) => number = t => t;

    constructor(animationDuration = 2000,  easingFunction = t => t) {
        this.animationDuration = animationDuration;
        this.easingFunction = easingFunction;
    }

    calcCurrentAnimationTime(): number {
        const dt = Date.now() - this.animationStartTimestamp;
        let t = Math.min(1, dt / this.animationDuration);
        const toTime = this.targetAnimationTime;
        const fromTime = 1 - toTime;
        t = MathUtils.lerp(fromTime, toTime, t);
        t = this.easingFunction(t);
        this.currentAnimationTime = t;
        return t;
    }

    setTargetAnimationTime(t: number) {
        this.targetAnimationTime = t;
        this.animationStartTimestamp = Date.now();
    }

}