import { NgModule } from '@angular/core';
import { Route, RouterModule } from '@angular/router';
import { CanLeaveGuard, RouterAnimationService } from '@rosatom/ui-common';
import { MainPageStubComponent } from './layout/main-page-stub/main-page-stub.component';
import { RouteTypeEnum } from '@rosatom/ui-common';

const routes: Array<Route> = [
  {
    path: '',
    canDeactivate: [CanLeaveGuard],
    data: { routeType: RouteTypeEnum.MainPage },
    component: MainPageStubComponent,
  },
  {
    path: 'product',
    data: { routeType: RouteTypeEnum.ProductPage },
    loadChildren: () => import('./lazy/product').then(m => m.ProductLazyModule),
  },
  {
    path: 'category',
    data: { routeType: RouteTypeEnum.CategoryPage },
    loadChildren: () => import('./lazy/category').then(m => m.CategoryLazyModule),
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {scrollPositionRestoration: 'top'}),
  ],
  providers: [
    CanLeaveGuard,
    RouterAnimationService,
  ]
})
export class AppRoutes {}
