<dp-contacts class="sidebar__contacts"></dp-contacts>

<dp-back-button
  class="sidebar__back"
  [text]="content.categoryTitle"
  [categorySlug]="content.categorySlug"
  [showHome]="true"
></dp-back-button>

<div class="sidebar__title" (click)="navClick(0)">
  <span [innerHTML]="content.title | stripHtml:'br':'span'"></span>
  <mat-icon *ngIf="showChevron" class="sidebar__title-chevron" svgIcon="dp-chevron"></mat-icon>
</div>

<div class="side-nav-wrap">
  <nav class="side-nav">
    <ng-container
      *ngFor="let product of content.products; let i = index"
    >
      <ng-container
        *ngIf="product.slug"
        [ngTemplateOutlet]="rLink"
        [ngTemplateOutletContext]="{ product: product }"
      >
      </ng-container>

      <ng-container
        *ngIf="!product.slug"
        [ngTemplateOutlet]="event"
        [ngTemplateOutletContext]="{ product: product, i: i }"
      >
      </ng-container>
    </ng-container>
  </nav>
</div>

<ng-template #rLink let-product="product">
  <a
    class="side-nav__link"
    [routerLink]="['/product', product.slug]"
  >
    <ng-container [ngTemplateOutlet]="linkInner" [ngTemplateOutletContext]="{ title: product.title }"></ng-container>
  </a>
</ng-template>

<ng-template #event let-i="i" let-product="product">
  <a
    class="side-nav__link"
    (click)="navClick(i + 1)"
  >
    <ng-container [ngTemplateOutlet]="linkInner" [ngTemplateOutletContext]="{ title: product.title }"></ng-container>
  </a>
</ng-template>

<ng-template #linkInner let-title="title">
    <span class="side-nav__link-text" [innerHTML]="title | stripHtml:'br':'span' | typograph">
    </span>
  <mat-icon class="side-nav__link-icon" svgIcon="arrow"></mat-icon>
</ng-template>

<ng-content></ng-content>
