<div class="dp-popup-window__header" mat-dialog-title>
  <div class="dp-popup-window__title">
    <mat-icon class="dp-popup-window__title-icon" [svgIcon]="icon"></mat-icon>

    <span [innerHTML]="title | stripHtml:'br':'span'"></span>
  </div>

  <button class="dp-popup-window__close-button" (click)="close.emit()">
    <mat-icon class="dp-popup-window__close-icon" svgIcon="close"></mat-icon>
  </button>
</div>

<ng-container *ngIf="isMobileDevice$ | async; else desktopContent" [ngTemplateOutlet]="mobileContent"></ng-container>

<ng-template #mobileContent>
  <div class="dp-popup-window__content-wrapper">
    <div class="dp-popup-window__content">
      <ng-content select="#mobile"></ng-content>
    </div>
  </div>
</ng-template>

<ng-template #desktopContent>
  <ng-scrollbar
    class="dp-popup-window__content-wrapper"
    trackClass="dp-popup-scrollbar-track"
    thumbClass="dp-popup-scrollbar-thumb"
  >
    <div class="dp-popup-window__content">
      <ng-content select="#desktop"></ng-content>
    </div>
  </ng-scrollbar>
</ng-template>
