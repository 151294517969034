<a
  class="contacts__link"
  href="tel:{{ staticText['CONTACTS_PHONE'] | stripHtml }}"
>
  {{ staticText['CONTACTS_PHONE'] | stripHtml }}
</a>
<a
  class="contacts__link"
  href="mailto:{{ staticText['CONTACTS_EMAIL'] | stripHtml }}"
>
  {{ staticText['CONTACTS_EMAIL'] | stripHtml }}
</a>
