import { Component, EventEmitter, HostBinding, Inject, Input, OnInit, Output } from '@angular/core';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { Subject } from 'rxjs';
import { FEEDBACK_FORM_POPUP_DATA } from './feedback-form-popup-data.token';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { HttpClient } from '@angular/common/http';

@Component({
  selector: 'dp-feedback-form-popup',
  templateUrl: './feedback-form-popup.component.html',
  styleUrls: ['./feedback-form-popup.component.styl'],
  animations: [
    trigger('removeTrigger', [
      state('closed', style({
        opacity: 0,
        transform: 'scale(1.2)'
      })),
      transition(':enter', [
        style({ opacity: 0, transform: 'scale(1.2)' }),
        animate('200ms', style({ opacity: 1, transform: 'scale(1)' })),
      ]),
      transition('* => closed', [
        animate('200ms')
      ]),
    ]),
  ],
})
export class FeedbackFormPopupComponent implements OnInit {
  @HostBinding('@removeTrigger') removeAnimation = '';
  @HostBinding('class.dp-feedback-form') componentClassName = true;
  closeClicked = new Subject();
  title: string;
  icon: string;
  type: string;
  product: string;

  constructor(
    private readonly fb: FormBuilder,
    private readonly http: HttpClient,
    @Inject(FEEDBACK_FORM_POPUP_DATA) data,
  ) {
    this.title = data.title;
    this.icon = data.icon;
    this.type = data.type;
    this.product = data.product;
  }

  ngOnInit(): void {
  }

  close() {
    this.removeAnimation = 'closed';
    setTimeout(() => this.closeClicked.next(), 200);
  }

}
