<button
  *ngIf="buttonVisible"
  class="menu-toggle"
  [ngClass]="{
    'menu-toggle_active': menuVisible,
    'menu-toggle_product': productColorScheme,
    '_negative-offset': productColorScheme && !(showTopPanel$ | async)
  }"
  (click)="toggleMenu()"
>
  <span class="menu-toggle__icon"><i></i></span>
</button>

<dp-main-menu
  *ngIf="menuVisible"
  (closeMenu)="onMenuToggle(false)"
>
</dp-main-menu>
