<div
  class="dp-feedback-form"
  [ngClass]="{ 'dp-feedback-form_main': colorScheme === 'main', 'dp-feedback-form_product': colorScheme === 'product' }"
  [formGroup]="form"
>
  <div class="dp-feedback-form__row">
    <label class="dp-feedback-form__label">
      <span class="dp-feedback-form__label-text dp-feedback-form__label-text_required">{{ staticText['CONTACT_US_FORM_FIELD_NAME'] | stripHtml }}</span>
      <input class="dp-feedback-form__input" formControlName="name">
    </label>
  </div>

  <div class="dp-feedback-form__row">
    <label class="dp-feedback-form__label">
      <span class="dp-feedback-form__label-text dp-feedback-form__label-text_required">{{ staticText['CONTACT_US_FORM_FIELD_EMAIL'] | stripHtml }}</span>
      <input class="dp-feedback-form__input" formControlName="email">
    </label>
  </div>

  <div class="dp-feedback-form__row">
    <label class="dp-feedback-form__label">
      <span class="dp-feedback-form__label-text">{{ staticText['CONTACT_US_FORM_FIELD_PHONE'] | stripHtml }}</span>
      <input class="dp-feedback-form__input" formControlName="phone" mask="(000) 000 00 00" prefix="+7 ">
    </label>
  </div>

  <div class="dp-feedback-form__row">
    <label class="dp-feedback-form__label">
      <span class="dp-feedback-form__label-text">{{ staticText['CONTACT_US_FORM_FIELD_COMPANY'] | stripHtml }}</span>
      <input class="dp-feedback-form__input" formControlName="company">
    </label>
  </div>

  <div class="dp-feedback-form__row dp-feedback-form__row_checkbox">
    <input class="dp-feedback-form__checkbox" type="checkbox" formControlName="pdAgreement" id="pd-checkbox">
    <label class="dp-feedback-form__checkbox-label" for="pd-checkbox">
      <span class="dp-feedback-form__label-text">
        {{ staticText['CONTACT_US_FORM_FIELD_PDA1'] | stripHtml }}
        <a class="dp-feedback-form__pd-link" href="assets/rosatom-pd-agreement.pdf" target="_blank">
          {{ staticText['CONTACT_US_FORM_FIELD_PDA2'] | stripHtml }}
        </a>
      </span>
    </label>
  </div>

  <button class="dp-feedback-form__submit" type="button" (click)="submit()">{{ staticText['CONTACT_US_FORM_SUBMIT'] | stripHtml }}</button>

  <div
    class="dp-feedback-form__error"
    *ngIf="error$ | async"
  >
    <div class="dp-feedback-form__error-content">
      {{ staticText['CONTACT_US_FORM_MSG_ERROR'] | stripHtml }}
    </div>
  </div>
  <div
    *ngIf="success$ | async"
    class="dp-feedback-form__success"
  >
    <div class="dp-feedback-form__success-content">
      {{ staticText['CONTACT_US_FORM_MSG_SUCCESS'] | stripHtml }}
    </div>
  </div>
</div>
