import {Animated} from "../base/Animated";
import {
    AdditiveBlending,
    BufferAttribute, BufferGeometry,
    Color,
    Points,
    PointsMaterial,
    ShaderMaterial,
    SphereBufferGeometry,
    TextureLoader
} from "three";

// @ts-ignore
import vertexShader from "../../glsl/surface.vert.glsl";

// @ts-ignore
import fragmentShader from "../../glsl/surface.frag.glsl";

export class Surface extends Animated {

    constructor() {
        super();

        const radius = 20;
        const vertices = []

        for (let i=0;i<400; i++){
            var u = Math.random();
            var v = Math.random();
            var theta = 2 * Math.PI * u;
            var phi = Math.acos(2 * v - 1);
            var x = (radius * Math.sin(phi) * Math.cos(theta));
            var y = (radius * Math.sin(phi) * Math.sin(theta));
            var z = (radius * Math.cos(phi));
            vertices.push(x,y,z)
        }


        const geometry = new BufferGeometry(  );
        geometry.setAttribute('position', new BufferAttribute(new Float32Array(vertices), 3));



        const material = new PointsMaterial({
            map: new TextureLoader().load('https://i.imgur.com/heHY5Ff.png'),
            size: 20,
            // alphaTest:0.5,
            opacity: 0.02,
            transparent: true,
            blending: AdditiveBlending,
            depthWrite: false
        });

        // material.onBeforeCompile = (shader) => {
        //
        //     shader.fragmentShader = shader.fragmentShader.split("#include <map_particle_fragment>").join(`
        //         vec2 uv = ( uvTransform * vec3( gl_PointCoord.x, 1.0 - gl_PointCoord.y, 1 ) ).xy;
        //         vec4 mapTexel = texture2D( map, uv );
        //
        //     `);
        //
        // };

        const cloud = new Points( geometry, material );

        this.add(cloud);
    }


}