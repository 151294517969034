import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MenuToggleButtonComponent } from './menu-toggle-button.component';
import { MainMenuModule } from '../main-menu';



@NgModule({
  declarations: [MenuToggleButtonComponent],
  exports: [
    MenuToggleButtonComponent
  ],
  imports: [
    CommonModule,
    MainMenuModule
  ]
})
export class MenuToggleButtonModule { }
