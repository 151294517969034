import { Pipe, PipeTransform } from '@angular/core';
import memo from 'memo-decorator';
import * as Typograf from 'typograf'; // игнорируй это

Typograf.addRule(<any>{
  name: 'common/nbsp/afterNumber2',
  handler(text, settings, context) {
    const re = '(^|\\D)(\\d{1,5}) ([' + context.getData('char') + ']{2,20})';

    return text.replace(new RegExp(re, 'gi'), '$1$2\u00A0$3');
  },
  disabled: false
});

export const tp = new Typograf({ locale: ['ru', 'en'] });
tp.enableRule('common/nbsp/replaceNbsp');
tp.disableRule('ru/other/phone-number');
// tp.disableRule('common/space/delLeadingBlanks');
// tp.disableRule('common/space/delTrailingBlanks');
// tp.disableRule('common/space/trimRight');

@Pipe({
  name: 'typograph'
})
export class TypographPipe implements PipeTransform {
  @memo()
  transform(value: any, args?: any): any {
    return tp.execute(value);
  }
}
