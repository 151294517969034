import {
    Object3D,
    PerspectiveCamera,
    Scene,
    sRGBEncoding,
    WebGLRenderer
} from "three";

import {Animated} from "./Animated";
import {CameraControls} from "./CameraControls";
import {Resizer} from "./Resizer";

export class AtomApp {

    private readonly renderer: WebGLRenderer;
    private readonly camera: PerspectiveCamera;
    private readonly resizer: Resizer;

    readonly scene: Scene;
    readonly controls: CameraControls;
    readonly electronsGroup = new Object3D();

    constructor(containerNode: HTMLElement) {
        this.scene = new Scene();
        this.scene.add(this.electronsGroup)
        this.renderer = new WebGLRenderer({
            antialias: true,
            alpha: true
        });
        this.renderer.outputEncoding = sRGBEncoding;
        this.camera = new PerspectiveCamera(40, 1, 1, 600);
        this.camera.position.setScalar(50);
        this.controls = new CameraControls(this.camera, containerNode, this.electronsGroup);
        this.resizer = new Resizer(this.renderer, this.camera);
        containerNode.insertBefore(this.renderer.domElement, containerNode.firstChild);
    }

    startRafLoop() {
        let stopped = false;
        const loop = (time) => {
            if (stopped) return;
            this.drawFrame(time / 1000);
            requestAnimationFrame(loop)
        };
        requestAnimationFrame(loop);
        return () => stopped = true;
    }

    private drawFrame(time: number) {
        this.resizer.handleResize();
        this.traverseAnimated(o => o.animateBeforeAnimateCam(time));
        this.controls.animateCam()
        this.traverseAnimated(o => o.animateAfterAnimateCam(time, this.camera));
        this.renderer.render(this.scene, this.camera);
        this.traverseAnimated(o => o.animateAfterRender(time, this.camera,
            this.controls.animationController.currentAnimationTime))
    }

    private traverseAnimated(callbackFn: (o) => any) {
        this.scene.traverse((o: Object3D) => {
            if (o instanceof Animated)
                callbackFn(o);
        });
    }
}
