import { Injectable, InjectionToken } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, zip } from 'rxjs';
import { map, tap } from 'rxjs/operators';

import { AppDataInterface, CategoryListType, ProductListType } from '../interfaces';
import { getDataUri } from '../utils';

export const appData = new InjectionToken<any>('Rosatom Digital Product Application Data');

@Injectable()
export class AppDataService {
  private readonly appData$: BehaviorSubject<AppDataInterface> = new BehaviorSubject<AppDataInterface>(null);

  constructor(
    private readonly http: HttpClient,
  ) { }

  get appData(): AppDataInterface {
    return this.appData$.getValue();
  }

  init(): Promise<AppDataInterface> {
    return zip(
      this.http.get<CategoryListType>(getDataUri('categories')),
      this.http.get<ProductListType>(getDataUri('pages')),
      this.http.get<Record<string, string>>(getDataUri('static_text')),
    )
      .pipe(
        map(([categoryList, productList, staticText]) => ({categoryList, productList, staticText})),
        tap(data => this.appData$.next(data)),
      )
      .toPromise();
  }

}
