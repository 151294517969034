var INF = 1e20;

// port of @mapbox/tinysdf

export class SdfCanvas {

    private radius: number;
    private cutoff: number;
    private width: any;
    private height: any;
    canvas: HTMLCanvasElement;
    ctx: CanvasRenderingContext2D;
    private gridOuter: Float64Array;
    private gridInner: Float64Array;
    private f: Float64Array;
    private d: Float64Array;
    private z: Float64Array;
    private v: Int16Array;

    constructor(width, height, radius = 8, cutoff = 0.25) {
        this.radius = radius;
        this.cutoff = cutoff;
        this.canvas = document.createElement('canvas');
        this.canvas.width = this.width = width;
        this.canvas.height = this.height = height;
        this.ctx = this.canvas.getContext('2d');

        // temporary arrays for the distance transform
        this.gridOuter = new Float64Array(width * height);
        this.gridInner = new Float64Array(width * height);

        // it is not correct, overhead here, but i didnt have a free time .
        const size = Math.max(width, height);

        this.f = new Float64Array(size);
        this.d = new Float64Array(size);
        this.z = new Float64Array(size + 1);
        this.v = new Int16Array(size);
    }

    singleChannel(){
        const imgData = this.ctx.getImageData(0, 0, this.width, this.height);
        const alphaChannel = new Uint8ClampedArray(this.width * this.height);
        for (let i = 0; i < this.width * this.height; i++) {
            const a = imgData.data[i * 4 + 3] / 255; // alpha value
            this.gridOuter[i] = a === 1 ? 0 : a === 0 ? INF : Math.pow(Math.max(0, 0.5 - a), 2);
            this.gridInner[i] = a === 1 ? INF : a === 0 ? 0 : Math.pow(Math.max(0, a - 0.5), 2);
        }
        this.edt(this.gridOuter, this.width, this.height, this.f, this.d, this.v, this.z);
        this.edt(this.gridInner, this.width, this.height, this.f, this.d, this.v, this.z);
        for (let i = 0; i < this.width * this.height; i++) {
            const d = this.gridOuter[i] - this.gridInner[i];
            alphaChannel[i] = Math.max(0, Math.min(255, Math.round(255 - 255 * (d / this.radius + this.cutoff))));
        }
        return alphaChannel;
    }

    // 2D Euclidean distance transform by Felzenszwalb & Huttenlocher https://cs.brown.edu/~pff/dt/
    edt(data, width, height, f, d, v, z) {
        for (let x = 0; x < width; x++) {
            for (let y = 0; y < height; y++)
                f[y] = data[y * width + x];
            this.edt1d(f, d, v, z, height);
            for (let y = 0; y < height; y++)
                data[y * width + x] = d[y];
        }
        for (let y = 0; y < height; y++) {
            for (let x = 0; x < width; x++)
                f[x] = data[y * width + x];
            this.edt1d(f, d, v, z, width);
            for (let x = 0; x < width; x++)
                data[y * width + x] = Math.sqrt(d[x]);
        }
    }

    // 1D squared distance transform
    edt1d(f, d, v, z, n) {
        v[0] = 0;
        z[0] = -INF;
        z[1] = +INF;
        for (let q = 1, k = 0; q < n; q++) {
            let s = ((f[q] + q * q) - (f[v[k]] + v[k] * v[k])) / (2 * q - 2 * v[k]);
            while (s <= z[k]) {
                k--;
                s = ((f[q] + q * q) - (f[v[k]] + v[k] * v[k])) / (2 * q - 2 * v[k]);
            }
            k++;
            v[k] = q;
            z[k] = s;
            z[k + 1] = +INF;
        }
        for (let q = 0, k = 0; q < n; q++) {
            while (z[k + 1] < q) k++;
            d[q] = (q - v[k]) * (q - v[k]) + f[v[k]];
        }
    }

    makeRGBAImageData() {
        const singleChannel = this.singleChannel();
        const imageData = this.ctx.createImageData(this.width, this.height);
        const data = imageData.data;
        for (let i = 0; i < singleChannel.length; i++) {
            data[4 * i    ] = singleChannel[i];
            data[4 * i + 1] = singleChannel[i];
            data[4 * i + 2] = singleChannel[i];
            data[4 * i + 3] = 255;
        }
        return imageData;
    }

    clear(){
        this.ctx.clearRect(0, 0, this.width, this.height);
    }

    drawImage(image) {
        this.clear();
        this.ctx.drawImage(image, 0, 0, this.width, this.height);
    }

    computeSdf() {
        this.ctx.putImageData(this.makeRGBAImageData(),0,0)
    }
}