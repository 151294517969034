import { Component, HostBinding } from '@angular/core';
import { AppDataService, CategoryListType } from '@rosatom/app-data';
import { Router } from '@angular/router';

@Component({
  selector: 'dp-main-page',
  templateUrl: './main-page.component.html',
  styleUrls: ['./main-page.component.styl'],
})
export class MainPageComponent {
  @HostBinding('class.dp-main-page') private componentClass = true;

  categoriesList: CategoryListType;
  private touchstart: { x: number, y: number };

  constructor(
    private readonly router: Router,
    private readonly appDataService: AppDataService,
  ) {
    this.categoriesList = this.appDataService.appData.categoryList;
  }

  onTouchstart(event) {
    this.touchstart = {
      x: event.targetTouches[0].clientX,
      y: event.targetTouches[0].clientY,
    };
  }

  goToCategory(slug: string, event) {
    const vector = {
      x: event.changedTouches[0].clientX - this.touchstart.x,
      y: event.changedTouches[0].clientY - this.touchstart.y,
    };
    const vectorLength = Math.sqrt( vector.x ** 2 + vector.y ** 2 );
    this.touchstart = undefined;
    if (vectorLength > 20) return;
    this.router.navigate(['/category', slug]);
  }

}
