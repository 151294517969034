<div class='sphere-tooltip__wrapper'>
  <div class='sphere-tooltip'
       [class.sphere-tooltip_hide]='hideTooltip'
       *ngIf='isNotPhone$ | async'>
    <div class='sphere-tooltip__ellipse-main' #tooltipMainEllipse></div>
    <div class='sphere-tooltip__control-wrap' #tooltipMainWrap>
      <div class='sphere-tooltip__core' #tooltipCore></div>
      <div class='sphere-tooltip__ellipse sphere-tooltip__ellipse_one' #tooltipEllipse></div>
      <div class='sphere-tooltip__ellipse sphere-tooltip__ellipse_two' #tooltipEllipse></div>
      <div class='sphere-tooltip__arrow sphere-tooltip__arrow_up' #tooltipArrow></div>
      <div class='sphere-tooltip__arrow sphere-tooltip__arrow_right' #tooltipArrow></div>
      <div class='sphere-tooltip__arrow sphere-tooltip__arrow_down' #tooltipArrow></div>
      <div class='sphere-tooltip__arrow sphere-tooltip__arrow_left' #tooltipArrow></div>
    </div>
  </div>
</div>
