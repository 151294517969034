import { Component, HostBinding, Input, Output, EventEmitter } from '@angular/core';
import { BreakpointObserver } from '@angular/cdk/layout';
import { map } from 'rxjs/operators';

@Component({
  selector: 'dp-popup-window',
  templateUrl: './popup-window.component.html',
  styleUrls: ['./popup-window.component.styl']
})
export class PopupWindowComponent {
  @HostBinding('class.dp-popup-window') private componentClass = true;
  @Output() close = new EventEmitter();
  @Input() title: string = '';
  @Input() icon: string = 'functional-capabilities';

  isMobileDevice$ = this.breakpointObserver.observe('(max-width: 1279px)')
    .pipe(
      map(result => result.matches),
    );

  constructor(
    private readonly breakpointObserver: BreakpointObserver,
  ) {
  }
}
