import { AfterViewInit, Component, ElementRef, ViewChild } from '@angular/core';
import { AtomControlService } from '../../services/atom-control.service';

@Component({
  selector: 'dp-atom',
  templateUrl: './dp-atom.component.html',
  styleUrls: ['./dp-atom.component.styl']
})
export class DpAtomComponent implements AfterViewInit {
  @ViewChild('atomContainer', { static: true })
  public atomContainer: ElementRef;

  constructor(
    private readonly atomControlService: AtomControlService,
  ) { }

  ngAfterViewInit() {
    this.atomControlService.initAtomApp(this.atomContainer.nativeElement);
  }
}
