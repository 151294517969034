import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';
import { MatDialogModule } from '@angular/material/dialog';

import { PopupWindowComponent } from './popup-window.component';
import { PopupConfigToken, popupWindowConfig } from './popup-window.config';
import { NgScrollbarModule } from 'ngx-scrollbar';
import { StripHtmlModule } from '../../pipes';

@NgModule({
  declarations: [PopupWindowComponent],
  imports: [
    CommonModule,
    MatIconModule,
    MatDialogModule,
    NgScrollbarModule,
    StripHtmlModule
  ],
  exports: [PopupWindowComponent],
  providers: [
    {
      provide: PopupConfigToken,
      useValue: popupWindowConfig,
    },
  ],
})
export class PopupWindowModule { }
