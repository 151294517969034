<button
  class="feedback-button__wrapper"
  (click)="openDialog()"
>
  <mat-icon
    class="feedback-button__icon"
    [svgIcon]="icon"
    aria-hidden="true"
  >
  </mat-icon>
  <span
    *ngIf="button !== 'icon-only'"
    class="feedback-button__text"
  >
    <ng-content></ng-content>
  </span>
</button>

