import { Injectable } from '@angular/core';
import { timer } from 'rxjs';
import { switchMap, switchMapTo } from 'rxjs/operators';
import { AppDataService, getCategorySlugByProductSlug } from '@rosatom/app-data';
import { AtomControlService } from '@rosatom/atom-app';
import { RouteTypeEnum } from './enums/route-type.enum';

@Injectable()
export class RouterAnimationService {

  constructor(
    private readonly atomControlService: AtomControlService,
    private readonly appDataService: AppDataService,
  ) {
  }

  change(prev, next) {

    switch (prev.routeType) {
      // Идем со страницы категории
      case RouteTypeEnum.CategoryPage:
        switch (next.routeType) {
          // на главную страницу
          case RouteTypeEnum.MainPage:
            return this.atomControlService.goToMainScreen();

          // на страницу продукта
          case RouteTypeEnum.ProductPage:
            return this.atomControlService.flashIn();
        }
        break;

      // Идем со страницы продукта
      case RouteTypeEnum.ProductPage:
        switch (next.routeType) {
          // на страницу категории
          case RouteTypeEnum.CategoryPage:
            return this.atomControlService.flashOut();

          // на главную страницу
          case RouteTypeEnum.MainPage:
            return this.atomControlService.flashOut()
              .pipe(switchMapTo(this.atomControlService.goToMainScreen()));
        }
        break;

      // С главной страницы
      case RouteTypeEnum.MainPage:
        // на страницу категории
        switch (next.routeType) {
          case RouteTypeEnum.CategoryPage:
            return this.atomControlService.goToElectronBySlug(next.slug);

          // на страницу продукта
          case RouteTypeEnum.ProductPage:
            const categorySlug = getCategorySlugByProductSlug(this.appDataService.appData.productList, next.slug);
            return this.atomControlService.goToElectronBySlug(categorySlug)
              .pipe(
                switchMap(() => this.atomControlService.flashIn()),
              );
        }
        break;

      default:
        break;
    }
    return timer(0);
  }
}
