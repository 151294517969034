<a
  class="rosatom-logo"
  [ngClass]="{ 'rosatom-logo_product': productColorScheme }"
  routerLink="/"
>
  <div class="rosatom-logo__image"></div>
  <!-- <mat-icon class="rosatom-logo__image" svgIcon="new-logo"></mat-icon>

  <ng-container *ngIf="logoMidText$ | async as logoMidText">
    <div class="rosatom-logo__mid-text">
      {{ logoMidText | stripHtml: 'br':'span' }}
    </div>

    <div class="rosatom-logo__separator"></div>
  </ng-container>

  <mat-icon class="rosatom-logo__text" svgIcon="rosatom-text"></mat-icon> -->
</a>
