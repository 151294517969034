import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FeedbackButtonComponent } from './feedback-button.component';
import { MatIconModule } from '@angular/material/icon';
import { FeedbackFormPopupModule } from '../feedback-form-popup/feedback-form-popup.module';



@NgModule({
  declarations: [FeedbackButtonComponent],
  imports: [
    CommonModule,
    MatIconModule,
    FeedbackFormPopupModule,
  ],
  exports: [FeedbackButtonComponent],
})
export class FeedbackButtonModule { }
