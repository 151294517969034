import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RosatomLogoOverlayComponent } from './rosatom-logo-overlay.component';
import { RosatomLogoModule } from '../rosatom-logo';

@NgModule({
  declarations: [RosatomLogoOverlayComponent],
  imports: [
    CommonModule,
    RosatomLogoModule
  ],
  exports: [RosatomLogoOverlayComponent],
})
export class RosatomLogoOverlayModule { }
