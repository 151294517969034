import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';

export function getStateTreeData(snapshot: ActivatedRouteSnapshot) {
  let data = {
    ...snapshot.data
  };

  if (snapshot.children.length) {
    data = { ...data, ...getStateTreeData(snapshot.children[0]) }
  }

  return data;
}

export function getStateData(state: RouterStateSnapshot) {
  let data = getStateTreeData(state.root);

  const segments = state.url.split('/');
  data.slug = segments[segments.length - 1];

  return data;
}
