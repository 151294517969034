import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SidebarComponent } from './sidebar.component';
import { MatIconModule } from '@angular/material/icon';
import { BackButtonModule } from '../back-button/back-button.module';
import { ContactsModule } from '../contacts/contacts.module';
import { RouterModule } from '@angular/router';
import { TypographPipeModule } from '../../pipes/typograph';
import { StripHtmlModule } from '../../pipes';

@NgModule({
  declarations: [SidebarComponent],
  exports: [SidebarComponent],
  imports: [
    CommonModule,
    ContactsModule,
    BackButtonModule,
    MatIconModule,
    RouterModule,
    TypographPipeModule,
    StripHtmlModule
  ]
})
export class SidebarModule { }
