import { ChangeDetectorRef, Component, ComponentRef, OnInit } from '@angular/core';
import { Overlay, OverlayConfig, OverlayRef } from '@angular/cdk/overlay';
import { ComponentPortal } from '@angular/cdk/portal';

import {
  MenuToggleButtonComponent,
  ContactUsButtonOverlayComponent,
  RosatomLogoOverlayComponent,
  getStateTreeData, RouteTypeEnum
} from '@rosatom/ui-common';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { delay, distinctUntilChanged, filter } from 'rxjs/operators';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';

@Component({
  selector: 'dp-layout',
  templateUrl: './layout.component.html',
  styleUrls: ['./layout.component.styl'],
})
@UntilDestroy()
export class LayoutComponent implements OnInit {
  atomVisible = true;

  constructor(
    private readonly router: Router,
    private readonly route: ActivatedRoute,
    private readonly cd: ChangeDetectorRef,
    private readonly overlay: Overlay,
  ) {
  }

  ngOnInit() {
    this.initRouterListener();
    this.initBurgerMenuOverlay();
    this.initContactUsOverlay();
    this.initLogoOverlay();
  }

  private initRouterListener() {
    this.router.events
      .pipe(
        filter((e): e is NavigationEnd => e instanceof NavigationEnd),
        untilDestroyed(this),
      )
      .subscribe(() => this.updateAtomVisibility());
  }

  private updateAtomVisibility() {
    const routeData = getStateTreeData(this.route.snapshot);
    this.atomVisible = routeData.routeType !== RouteTypeEnum.PrintPage;
    this.cd.detectChanges();
  }

  private initContactUsOverlay() {
    const contactUsOverlay = this.overlay.create(this.getOverlayConfig('contact-us-overlay'));
    const contactUsPortal = new ComponentPortal(ContactUsButtonOverlayComponent);
    contactUsOverlay.attach(contactUsPortal);
    contactUsOverlay.hostElement.classList.add('global-overlay-wrapper');
  }

  private initLogoOverlay() {
    const logoOverlay = this.overlay.create(this.getOverlayConfig('logo-main-overlay'));
    const logoPortal = new ComponentPortal(RosatomLogoOverlayComponent);
    logoOverlay.attach(logoPortal);
    logoOverlay.hostElement.classList.add('global-overlay-wrapper');
  }

  private initBurgerMenuOverlay() {
    const burgerOverlay = this.overlay.create(this.getOverlayConfig('burger-overlay'));
    const burgerButtonPortal = new ComponentPortal(MenuToggleButtonComponent);
    const burgerButton = burgerOverlay.attach(burgerButtonPortal);
    burgerOverlay.hostElement.classList.add('burger-overlay-wrapper');

    burgerButton.instance
      .menuToggle
      .pipe(
        delay(205),
        distinctUntilChanged(),
        untilDestroyed(this),
      )
      .subscribe(isMenuVisible => {
        setTimeout(() => {
          if (isMenuVisible)
            burgerOverlay.updateScrollStrategy(this.overlay.scrollStrategies.block());
          else
            burgerOverlay.updateScrollStrategy(this.overlay.scrollStrategies.noop());
        }, 251);
      });
  }

  private getOverlayConfig(panelClass: string): OverlayConfig {
    return new OverlayConfig({
      panelClass,
      hasBackdrop: false,
      scrollStrategy: this.overlay.scrollStrategies.noop(),
      positionStrategy: this.overlay.position().global(),
    });
  }

}
