import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'stripHtml'
})
export class StripHtmlPipe implements PipeTransform {

  transform(value: string, ...tagsToKeep: Array<string>): string {
    return value.replace(/<\/?(.*?) ?\/?>/ig, (match, tagName) => {
      const foundIndex = tagsToKeep.findIndex(tag => tag === tagName);
      return foundIndex < 0 ? '' : match;
    });
  }

}
