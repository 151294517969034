import {PerspectiveCamera, Vector3} from "three";

export class Link {

    readonly a: HTMLAnchorElement;

    constructor(a: HTMLAnchorElement) {
        this.a = a;
        this.a.style.position = 'absolute';
        this.a.style.top = '0';
        this.a.style.left = '0';
        this.a.setAttribute('draggable', 'false');
    }

    relocateHtmlElement(
        electronWorldPosition: Vector3,
        cameraTravelPosition: number,
        camera: PerspectiveCamera) {


        const distanceToCamera = electronWorldPosition.distanceTo(camera.position);

        electronWorldPosition.project(camera);

        const w = innerWidth / 2;
        const h = innerHeight / 2;
        const x = electronWorldPosition.x * w;
        const y = (-electronWorldPosition.y) * h;
        let opacity = Math.max(0, 1 - cameraTravelPosition*2);
        if (distanceToCamera>90.7)
            opacity = 0;
        this.a.style.pointerEvents = opacity === 0 ? 'none' : 'unset'
        this.a.style.opacity = '' + opacity;
        this.a.style.transform = `translate(${w + x}px, ${h + y}px)`

        // this.textDiv.style.textAlign = p.x > 0.5 ? "left" : "right";
    }
}