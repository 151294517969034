import {Animated} from "../base/Animated";
import {AdditiveBlending, BufferGeometry, Camera, Line, LineBasicMaterial, PerspectiveCamera, Vector3} from "three";

export class Orbit extends Animated {

    private readonly index: number;
    private readonly speed: number;

    constructor(index: number, speed: number) {
        super();
        this.speed = speed;
        this.index = index;
        this.rotation.x = Math.floor(index/2) * (Math.PI*2/6);

    }

    animateBeforeAnimateCam(time: number) {
        this.rotation.y = Math.floor(this.index/2)*Math.PI/3 + this.index*Math.PI + time * this.speed;
    }

    createMeshLineOrbit(r) {
        const points = [];
        for (let j = 0; j < Math.PI * 2; j += (2 * Math.PI) / 100) {
            points.push(new Vector3( Math.cos(j) * r, 0, Math.sin(j) * r));
        }
        const material = new LineBasicMaterial({
            color: 0xffffff
        });
        const geometry = new BufferGeometry().setFromPoints(points);
        const line = new Line(geometry, material);
        this.add(line);
    }

}