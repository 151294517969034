import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FeedbackFormComponent } from './feedback-form.component';
import { ReactiveFormsModule } from '@angular/forms';
import { NgxMaskModule } from 'ngx-mask';
import { StripHtmlModule } from '../../pipes';



@NgModule({
  declarations: [FeedbackFormComponent],
  exports: [
    FeedbackFormComponent
  ],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    NgxMaskModule.forChild(),
    StripHtmlModule
  ]
})
export class FeedbackFormModule { }
