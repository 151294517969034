import { ChangeDetectorRef, Component, EventEmitter, HostBinding, OnInit, Output } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';

import { BehaviorSubject } from 'rxjs';
import { filter, map, startWith, tap } from 'rxjs/operators';

import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

import { RouteTypeEnum } from '../../enums/route-type.enum';

import { getStateTreeData } from '../../utils';
import { TopPanelShowService } from '../../top-panel-show.service';

@Component({
  selector: 'dp-menu-toggle-button',
  templateUrl: './menu-toggle-button.component.html',
  styleUrls: ['./menu-toggle-button.component.styl']
})
@UntilDestroy()
export class MenuToggleButtonComponent implements OnInit {
  @Output() menuToggle: EventEmitter<boolean> = new EventEmitter<boolean>();
  showTopPanel$ = new BehaviorSubject(true);
  showBackground$ = new BehaviorSubject(false);
  menuVisible: boolean = false;
  buttonVisible: boolean = true;

  @HostBinding('class.product-color')
  productColorScheme: boolean = false;

  @HostBinding('class.category-page')
  isCategoryPage: boolean = false;

  @HostBinding('class._negative-offset')
  get negativeOffset() {
    return !this.showTopPanel$.getValue();
  };

  @HostBinding('class._with-background')
  get withBackground() {
    return this.showBackground$.getValue();
  };

  constructor(
    private readonly router: Router,
    private readonly route: ActivatedRoute,
    private readonly cd: ChangeDetectorRef,
    private topPanelShowService: TopPanelShowService,
  ) {
  }

  ngOnInit() {
    this.updateColorScheme();
    this.updateButtonVisibility();
    this.initRouterListener();
    this.initScrollWatchers();
  }

  private initScrollWatchers() {
    this.topPanelShowService.showTopPanel$
      .pipe(
        untilDestroyed(this),
      )
      .subscribe(this.showTopPanel$);

    this.topPanelShowService.scrollY$
      .pipe(
        map(scrollY => scrollY > 0),
        untilDestroyed(this),
      )
      .subscribe(this.showBackground$);
  }

  private initRouterListener() {
    this.router.events
      .pipe(
        filter((e): e is NavigationEnd => e instanceof NavigationEnd),
        untilDestroyed(this),
      )
      .subscribe(() => {
        this.updateColorScheme();
        this.updateButtonVisibility();
        this.cd.detectChanges();
      });
  }

  private updateColorScheme() {
    const routeData = getStateTreeData(this.route.snapshot);
    this.productColorScheme = routeData.routeType === RouteTypeEnum.ProductPage;
    this.isCategoryPage = routeData.routeType === RouteTypeEnum.CategoryPage;
  }

  private updateButtonVisibility() {
    const routeData = getStateTreeData(this.route.snapshot);
    this.buttonVisible = routeData.routeType !== RouteTypeEnum.PrintPage;
  }

  onMenuToggle(isMenuVisible: boolean) {
    setTimeout(() => {
      this.menuVisible = isMenuVisible;
      this.menuToggle.emit(isMenuVisible);
    });
  }

  toggleMenu() {
    this.onMenuToggle(!this.menuVisible);
  }

}
