import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FeedbackFormPopupComponent } from './feedback-form-popup.component';
import { MatIconModule } from '@angular/material/icon';
import { StripHtmlModule } from '../../pipes';
import { ReactiveFormsModule } from '@angular/forms';
import { FeedbackFormModule } from '../feedback-form/feedback-form.module';

@NgModule({
  declarations: [FeedbackFormPopupComponent],
  imports: [
    CommonModule,
    MatIconModule,
    StripHtmlModule,
    ReactiveFormsModule,
    FeedbackFormModule
  ],
  exports: [
    FeedbackFormPopupComponent
  ],
  entryComponents: [FeedbackFormPopupComponent],
})
export class FeedbackFormPopupModule { }
