import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanDeactivate, RouterStateSnapshot } from '@angular/router';
import { combineLatest, Observable } from 'rxjs';
import { mapTo, take } from 'rxjs/operators';
import { RouterAnimationService } from './router-animation.service';
import { getStateData } from './utils';

export interface BeforeLeaveComponent {
  /** Метод говорит когда можно отпустить роут при выходе с него.
   * Хорошо подходит для выходной анимации
   *
   * Чтобы он сработал, надо добавить к роуту canLeave: [CanLeaveGuard]
   * */
  beforeLeave(prev?, next?): Observable<boolean> | Promise<boolean>;
}

@Injectable()
export class CanLeaveGuard implements CanDeactivate<BeforeLeaveComponent> {
  constructor(
    private routerAnimation: RouterAnimationService,
  ) {

  }

  public canDeactivate(
    component: BeforeLeaveComponent,
    _currentRoute: ActivatedRouteSnapshot,
    currentState: RouterStateSnapshot,
    nextState?: RouterStateSnapshot):  Observable<boolean> | Promise<boolean> | boolean {
    const prev = getStateData(currentState);
    const next = getStateData(nextState);

    return combineLatest([
      component && component.beforeLeave ? component.beforeLeave(prev, next) : Promise.resolve(true),
      this.routerAnimation.change(prev, next)
    ]).pipe(
      mapTo(true),
      take(1)
    );
  }
}
