import {PerspectiveCamera, Vector3} from "three";
import {PlaneObject} from "../base/PlaneObject";
import {AnimationController} from "../base/AnimationController";

// @ts-ignore
import background from "../../glsl/background.frag.glsl";

export class Background extends PlaneObject {

    private tmp: Vector3 = new Vector3();

    animationController = new AnimationController(1000);

    constructor() {
        super(16, background);
    }

    extendUniforms() {
        this.uniforms.aspect = {value: 1};
        this.uniforms.flare = {value: 0};
    }

    animateAfterAnimateCam(time: number, camera: PerspectiveCamera) {
        camera.getWorldDirection(this.tmp);
        this.position.copy(camera.position)
            .add(this.tmp.multiplyScalar(10))
        this.scale.x = camera.aspect;
        this.uniforms.aspect.value = camera.aspect;
        this.uniforms.flare.value = this.animationController.calcCurrentAnimationTime();
        super.animateAfterAnimateCam(time, camera);
    }

}