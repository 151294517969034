import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { distinctUntilChanged, filter, map, shareReplay, startWith, switchMapTo, tap } from 'rxjs/operators';

@Injectable()
export class TopPanelShowService {
  private _breakPointScrollY: number = 0;
  fixTopPanel = false;

  touchEvents: BehaviorSubject<{ type: 'scroll' | 'touchstart', scrollY: number }> = new BehaviorSubject({ type: 'scroll', scrollY: 0 });

  scrollY$ = this.touchEvents.asObservable()
    .pipe(
      filter(event => event?.type === 'scroll'),
      map(event => event.scrollY),
    );

  showTopPanel$: Observable<boolean> = this.touchEvents
    .pipe(
      filter(event => !!event),
      tap(event => {
        if (event.type === 'touchstart') {
          this.updateBreakpointPosition(event.scrollY);
        }
      }),
      filter(event => event.type === 'scroll'),
      map((event) => ({...event, delta: event.scrollY - this._breakPointScrollY})),
      filter(event => event.scrollY === 0 || Math.abs(event.delta) > 50),
      tap(event => this._breakPointScrollY = event.scrollY),
      map(event => event.scrollY === 0 || event.delta < 0 || this.fixTopPanel),
      startWith(true),
      distinctUntilChanged(),
      shareReplay(1),
    ) as Observable<boolean>;

  constructor() {
  }

  updateBreakpointPosition(scrollY: number) {
    this._breakPointScrollY = scrollY;
  }
}
