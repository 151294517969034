// @ts-ignore
import nucleus from "../../glsl/nucleus.frag.glsl";

import {PlaneObject} from "../base/PlaneObject";

export class Nucleus extends PlaneObject {

    constructor() {
        super(60, nucleus);
    }

}