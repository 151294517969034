import {PerspectiveCamera, Renderer} from "three";

export class Resizer {

    private width: number = 0;
    private height: number = 0;

    private renderer: Renderer;
    private camera: PerspectiveCamera;

    constructor(renderer: Renderer, camera: PerspectiveCamera) {
        this.renderer = renderer;
        this.camera = camera;
    }

    handleResize() {
        const parentNode: any = this.renderer.domElement.parentNode;
        const size = parentNode.getBoundingClientRect();
        if (size.width === this.width && size.height === this.height)
            return

        this.height = size.height;
        this.width = size.width;
        this.renderer.setSize(this.width, this.height);
        this.camera.aspect = this.width / this.height;
        const vAspect = this.height / this.width;
        const fov = 29 * vAspect;
        this.camera.fov =  fov < 40 ? 40 : fov;
        this.camera.updateProjectionMatrix();
    }
}
