import { Component, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

import { MatIconRegistry } from '@angular/material/icon';
import { fromEvent, merge } from 'rxjs';

import { filter, map } from 'rxjs/operators';
import { getStateTreeData, RosatomLogoService, RouteTypeEnum, TopPanelShowService } from '@rosatom/ui-common';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';

@Component({
  selector: 'dp-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.styl'],
})
@UntilDestroy()
export class AppComponent implements OnInit {

  constructor(
    private readonly iconRegistry: MatIconRegistry,
    private readonly sanitizer: DomSanitizer,
    private readonly router: Router,
    private readonly route: ActivatedRoute,
    private readonly topPanelShowService: TopPanelShowService,
    private readonly logoService: RosatomLogoService,
  ) {
    this.iconRegistry.addSvgIconSet(this.sanitizer.bypassSecurityTrustResourceUrl('assets/svg/icon-set.svg'));
    window.oncontextmenu = ev => ev.preventDefault();
  }

  ngOnInit() {
    this.initRouterListener();
    this.initTouchListener();
    this.initLogoMidTextReset();
  }

  private initLogoMidTextReset() {
    this.router.events
      .pipe(
        filter((e): e is NavigationEnd => e instanceof NavigationEnd),
        untilDestroyed(this),
      )
      .subscribe(() => {
        const routeData = getStateTreeData(this.route.snapshot);
        if (routeData.routeType !== RouteTypeEnum.ProductPage) {
          this.logoService.resetLogoMidText();
        }
      });
  }

  private initTouchListener() {
    merge(
      fromEvent(window, 'touchstart'),
      fromEvent(window, 'scroll'),
    )
      .pipe(
        map(event => {
          return {
            scrollY: this.getYPosition(),
            type: event.type,
          };
        }),
        untilDestroyed(this),
      )
      .subscribe(this.topPanelShowService.touchEvents);
  }

  private initRouterListener() {
    this.router.events
      .pipe(
        filter((e): e is NavigationEnd => e instanceof NavigationEnd),
        untilDestroyed(this),
      )
      .subscribe(() => {
        this.topPanelShowService.touchEvents.next({ type: 'touchstart', scrollY: 0 });
        this.topPanelShowService.touchEvents.next({ type: 'scroll', scrollY: 0 });
      });
  }

  getYPosition(): number {
    const scrollHeight = (document.scrollingElement || document.body).scrollHeight - window.innerHeight;
    let scrollTop = (document.scrollingElement || document.body).scrollTop;
    scrollTop = scrollTop < 0 ? 0 :
      scrollTop > scrollHeight ? scrollHeight :
        scrollTop;
    return scrollTop;
  }
}
