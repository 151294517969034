import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MainMenuComponent } from './main-menu.component';
import { RouterModule } from '@angular/router';
import { TypographPipeModule } from '../../pipes/typograph';
import { CdkScrollableModule } from '@angular/cdk/scrolling';
import { StripHtmlModule } from '../../pipes';
import { NgScrollbarModule } from 'ngx-scrollbar';



@NgModule({
  declarations: [MainMenuComponent],
  exports: [
    MainMenuComponent
  ],
  imports: [
    CommonModule,
    RouterModule,
    TypographPipeModule,
    CdkScrollableModule,
    StripHtmlModule,
    NgScrollbarModule,
  ]
})
export class MainMenuModule { }
