import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ContactUsButtonOverlayComponent } from './contact-us-button-overlay.component';
import { FeedbackButtonModule } from '../feedback-button/feedback-button.module';
import { StripHtmlModule } from '../../pipes';



@NgModule({
  declarations: [ContactUsButtonOverlayComponent],
  imports: [
    CommonModule,
    FeedbackButtonModule,
    StripHtmlModule
  ]
})
export class ContactUsButtonOverlayModule { }
