import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { filter, map } from 'rxjs/operators';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { getStateTreeData } from '../../utils';
import { RouteTypeEnum } from '../../enums/route-type.enum';
import { BreakpointObserver } from '@angular/cdk/layout';
import { environment } from '../../../../../../apps/digital-product/src/environments/environment';
import { AppDataService } from '@rosatom/app-data';

@Component({
  selector: 'dp-contact-us-button-overlay',
  templateUrl: './contact-us-button-overlay.component.html',
  styleUrls: ['./contact-us-button-overlay.component.styl']
})
@UntilDestroy()
export class ContactUsButtonOverlayComponent implements OnInit {
  showButton: boolean;

  isNotPhone$ = this.breakpointObserver.observe('(min-width: 768px)')
    .pipe(
      map(result => result.matches),
    );
  staticText = this.appDataService.appData.staticText;

  constructor(
    private readonly router: Router,
    private readonly route: ActivatedRoute,
    private readonly breakpointObserver: BreakpointObserver,
    private readonly appDataService: AppDataService,
    private readonly cd: ChangeDetectorRef,
  ) { }

  ngOnInit(): void {
    this.initRouterListener();
  }

  private initRouterListener() {
    this.router.events
      .pipe(
        filter((e): e is NavigationEnd => e instanceof NavigationEnd),
        untilDestroyed(this),
      )
      .subscribe(() => this.updateButton());
  }

  private updateButton() {
    const routeData = getStateTreeData(this.route.snapshot);
    this.showButton = !environment['electronApp'] && routeData.routeType !== RouteTypeEnum.ProductPage && routeData.routeType !== RouteTypeEnum.PrintPage;
    this.cd.detectChanges();
  }

}
