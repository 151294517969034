import {Animated} from "./Animated";
import {AdditiveBlending, Camera, Mesh, PlaneBufferGeometry, ShaderMaterial, Vector3} from "three";

// @ts-ignore
import defaultVertexShader from "../../glsl/default.vert.glsl";

export class PlaneObject extends Animated {

    uniforms: any = {
        time: {value: 0},
        eye: {value: new Vector3()}
    };

    constructor(size: number, fragmentShader: string) {
        super();

        this.extendUniforms();

        const material = new ShaderMaterial({
            uniforms: this.uniforms,
            depthWrite: false,
            transparent: true,
            vertexShader: defaultVertexShader,
            blending: AdditiveBlending,
            fragmentShader
        });

        const geometry = new PlaneBufferGeometry(size, size);

        const mesh = new Mesh(geometry, material);

        this.add(mesh);
    }

    animateAfterAnimateCam(time: number, camera: Camera) {
        this.uniforms.time.value = time;
        this.uniforms.eye.value = camera.position;
        this.lookAt(camera.position);
    }

    extendUniforms() {}
}