import { Component, EventEmitter, HostBinding, Input, OnInit, Output } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { filter } from 'rxjs/operators';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { getStateTreeData } from '../../utils';
import { RouteTypeEnum } from '../../enums/route-type.enum';

@Component({
  selector: 'dp-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.styl']
})
@UntilDestroy()
export class SidebarComponent implements OnInit {
  @HostBinding('class.sidebar') componentClassName = true;
  @HostBinding('class.sidebar_product') productColorScheme = false;
  @Output() sidenavClicked: EventEmitter<string | number> = new EventEmitter<string | number>();
  @Input() showChevron = false;
  @Input() content: any;

  constructor(
    private readonly router: Router,
    private readonly route: ActivatedRoute,
  ) {
  }

  ngOnInit() {
    this.updateColorScheme();
    this.initRouterListener();
  }

  private initRouterListener() {
    this.router.events
      .pipe(
        filter((e): e is NavigationEnd => e instanceof NavigationEnd),
        untilDestroyed(this),
      )
      .subscribe(() => this.updateColorScheme());
  }

  private updateColorScheme() {
    const routeData = getStateTreeData(this.route.snapshot);
    this.productColorScheme = routeData.routeType === RouteTypeEnum.ProductPage;
  }

  navClick(param: string | number) {
    this.sidenavClicked.emit(param);
  }
}
