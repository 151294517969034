import { Component, HostBinding } from '@angular/core';
import { AppDataService } from '@rosatom/app-data';

@Component({
  selector: 'dp-contacts',
  templateUrl: './contacts.component.html',
  styleUrls: ['./contacts.component.styl']
})
export class ContactsComponent {
  @HostBinding('class.contacts') componentClass = true;
  staticText = this.appDataService.appData.staticText;

  constructor(
    private readonly appDataService: AppDataService,
  ) {
  }
}
