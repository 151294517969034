import {
    BoxGeometry,
    Camera, Mesh, MeshNormalMaterial,
    Object3D, PerspectiveCamera,
    Vector3
} from "three";

import {Animated} from "../base/Animated";
import {Orbit} from "./Orbit";
import {Link} from "./Link";
import {GlowingDot} from "./GlowingDot";

export class Electron extends Animated {

    private tmp = new Vector3();

    readonly electronObject: Object3D;
    link: Link;
    readonly cameraPoint: Object3D;
    readonly index: number;

    constructor(i: number) {
        super();

        this.index = i;
        const sign = 1;
        const electronOrbitRadius = 27;
        const shift = sign * electronOrbitRadius;

        this.electronObject = new GlowingDot(50, shift);

        this.cameraPoint = new Object3D();

        this.cameraPoint.position
            .copy(this.electronObject.position)
            .sub(new Vector3(shift,0, shift))
            .normalize()
            .multiplyScalar(20)
            .add(this.electronObject.position)

        for (let j = 0; j < 3; j++) {
            const subOrbit = new Orbit(Math.random()*100, 3);
            subOrbit.add(new GlowingDot(5, 0.14));
            this.electronObject.add(subOrbit);
        }

        const electronOrbit = new Orbit(i, 0.03);
        electronOrbit.add(this.electronObject);
        electronOrbit.add(this.cameraPoint);
        // electronOrbit.createMeshLineOrbit(electronOrbitRadius);
        this.add(electronOrbit);
    }

    animateAfterRender(time: number, camera: PerspectiveCamera, currentAnimationTime: number) {
        this.electronObject.getWorldPosition(this.tmp);

        this.link && this.link.relocateHtmlElement(this.tmp, currentAnimationTime, camera);
    }
}