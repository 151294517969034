import {OrbitControls} from "three/examples/jsm/controls/OrbitControls";
import {MathUtils, Object3D, PerspectiveCamera, Vector3} from "three";
import {Electron} from "../elements/Electron";
import {AnimationController} from "./AnimationController";

export class CameraControls extends OrbitControls {

    private zero = new Vector3();
    private tmp = new Vector3();

    private electronsGroup: Object3D;

    animationController = new AnimationController(2000, t => 3*t*t - 2*t*t*t)

    constructor(camera: PerspectiveCamera, activeElement: HTMLElement, electronsGroup: Object3D) {
        super(camera, activeElement);
        this.electronsGroup = electronsGroup;
        this.enablePan = false;
        this.enableZoom = false;
        this.rememberOrbitingCameraPoint()
        this.addEventListener('change',
            () => this.rememberOrbitingCameraPoint());
        this.enableDamping = true;
    }

    rememberOrbitingCameraPoint(){
        this.orbitingCameraPoint.copy(this.object.position)
    }

    animationDuration = 2000;
    activeElectron: Electron;

    private prevFrameAnimationTime = 0;
    private orbitingCameraPoint = new Vector3();
    private electronCameraPoint = new Vector3();



    animateCam(){
        let t = this.animationController.currentAnimationTime;
        if (t === this.animationController.targetAnimationTime && t === 0)
            return this.update();
        t = this.animationController.calcCurrentAnimationTime();
        this.animateCameraPosition();
        this.animateCameraDirection();
        this.animateElectronsGroupRotation();
        this.tryToEmitEvents();
        this.enabled = t === 0;

    }

    private tryToEmitEvents() {
        const t = this.animationController.currentAnimationTime;
        if (t !== this.prevFrameAnimationTime) {
            if (t === 1)
                this.activeElectron.link.a.dispatchEvent(new CustomEvent('camera-fly-end'));
            else if (t === 0)
                this.domElement.dispatchEvent(new CustomEvent('camera-fly-end'));
        }
        this.prevFrameAnimationTime = t;
    }

    private animateCameraPosition() {
        this.activeElectron.cameraPoint.getWorldPosition(this.electronCameraPoint);
        const t = 1 - this.animationController.currentAnimationTime;
        this.electronCameraPoint.lerp(this.orbitingCameraPoint, t);
        this.object.position.copy(this.electronCameraPoint);
    }


    private animateCameraDirection() {
        this.activeElectron.electronObject.getWorldPosition(this.tmp);
        const t = 1 - this.animationController.currentAnimationTime;
        this.tmp.lerp(this.zero, t);
        this.object.lookAt(this.tmp)

    }

    private animateElectronsGroupRotation() {
        const x = -Math.floor(this.activeElectron.index/2)*Math.PI/3;
        const t = this.animationController.currentAnimationTime;
        this.electronsGroup.rotation.x = MathUtils.lerp(0, x, t)
    }
}