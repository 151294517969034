import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RosatomLogoComponent } from './rosatom-logo.component';
import { MatIconModule } from '@angular/material/icon';
import { RouterModule } from '@angular/router';
import { StripHtmlModule } from '../../pipes';



@NgModule({
  declarations: [RosatomLogoComponent],
  exports: [
    RosatomLogoComponent
  ],
  imports: [
    CommonModule,
    MatIconModule,
    RouterModule,
    StripHtmlModule
  ]
})
export class RosatomLogoModule { }
