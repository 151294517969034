import { Component, HostBinding, Input } from '@angular/core';
import { AppDataService } from '@rosatom/app-data';

@Component({
  selector: 'dp-back-button',
  templateUrl: './back-button.component.html',
  styleUrls: ['./back-button.component.styl']
})
export class BackButtonComponent {
  @HostBinding('class.back-button') componentClassName = true;
  @Input() text: string;
  @Input() showHome: boolean;
  @Input() categorySlug: string;
  @Input() productSlug: string;
  title = this.appDataService.appData.staticText['BUTTON_GOTO_MAIN_PAGE'];

  constructor(
    private readonly appDataService: AppDataService,
  ) {
  }
}
