import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { APP_INITIALIZER, NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { HttpClientModule } from '@angular/common/http';
import { OverlayModule } from '@angular/cdk/overlay';
import { MatIconModule } from '@angular/material/icon';

import { AppRoutes } from './app.routes';
import { AppComponent } from './app.component';

import {
  ContactUsButtonOverlayModule,
  FeedbackButtonModule,
  MenuToggleButtonModule,
  StripHtmlModule,
  TypographPipeModule,
  TopPanelShowService
} from '@rosatom/ui-common';
import { DpAtomModule } from '@rosatom/atom-app';
import { MainMenuModule } from '@rosatom/ui-common';
import { AppDataService } from '@rosatom/app-data';

import { LayoutComponent } from './layout/layout.component';
import { MainPageComponent } from './layout/main-page/main-page.component';
import { MainPageStubComponent } from './layout/main-page-stub/main-page-stub.component';
import { NgxMaskModule } from 'ngx-mask';

function appDataInit(appDataService: AppDataService) {
  return (): Promise<any> => appDataService.init();
}

@NgModule({
  declarations: [
    AppComponent,
    LayoutComponent,
    MainPageComponent,
    MainPageStubComponent,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    RouterModule,
    AppRoutes,
    MatIconModule,
    MenuToggleButtonModule,
    MainMenuModule,
    DpAtomModule,
    OverlayModule,
    TypographPipeModule,
    StripHtmlModule,
    ContactUsButtonOverlayModule,
    FeedbackButtonModule,
    NgxMaskModule.forRoot(),
  ],
  providers: [
    AppDataService,
    {
      provide: APP_INITIALIZER,
      useFactory: appDataInit,
      deps: [AppDataService],
      multi: true,
    },
    TopPanelShowService,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
