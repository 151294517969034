import {
  AfterViewInit, ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  HostBinding,
  HostListener,
  OnInit,
  Output,
  ViewChild
} from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { filter, map } from 'rxjs/operators';
import { AppDataService } from '@rosatom/app-data';
import { CategoryListType } from '@rosatom/app-data';
import { getStateTreeData } from '../../utils';
import { RouteTypeEnum } from '../../enums/route-type.enum';
import { animate, style, transition, trigger } from '@angular/animations';
import { CdkScrollable } from '@angular/cdk/overlay';
import { NgScrollbar } from 'ngx-scrollbar';
import { fromEvent } from 'rxjs';
import { BreakpointObserver } from '@angular/cdk/layout';

@Component({
  selector: 'dp-main-menu',
  templateUrl: './main-menu.component.html',
  styleUrls: ['./main-menu.component.styl'],
  animations: [
    trigger('insertRemoveTrigger', [
      transition(':enter', [
        style({ opacity: 0, transform: 'scale(1.2)' }),
        animate('200ms', style({ opacity: 1, transform: 'scale(1)' })),
      ]),
      transition(':leave', [
        animate('200ms', style({ opacity: 0, transform: 'scale(1.2)' }))
      ])
    ]),
  ],
})
@UntilDestroy()
export class MainMenuComponent implements OnInit, AfterViewInit {
  @ViewChild('scrollbar', {read: NgScrollbar}) scrollbar: NgScrollbar;
  @Output() closeMenu: EventEmitter<any> = new EventEmitter<any>();
  @HostBinding('@insertRemoveTrigger') get insertRemoveAnimation() { return true };
  @HostBinding('class.main-menu') componentClass = true;
  @HostBinding('class.main-menu_product') productColorScheme: boolean = false;
  @ViewChild('[cdkScrollable]') scrollable: CdkScrollable;

  categoriesList: CategoryListType;
  scrollbarIsVisible = false;
  isNotPhone = false;

  isNotPhone$ = this.breakpointObserver.observe('(min-width: 768px)')
    .pipe(
      map(result => result.matches),
    );

  constructor(
    private readonly router: Router,
    private readonly route: ActivatedRoute,
    private readonly appDataService: AppDataService,
    private cd: ChangeDetectorRef,
    private breakpointObserver: BreakpointObserver
  ) {
    this.categoriesList = this.appDataService.appData.categoryList;
  }

  ngOnInit() {
    this.updateColorScheme();
    this.initRouterListener();
  }

  ngAfterViewInit(): void {
    this.isNotPhone$
      .pipe(untilDestroyed(this))
      .subscribe((event) => {
      this.isNotPhone = event;
      if (event) {
        this.scrollbarIsVisible = this.scrollbar.state.isVerticallyScrollable;
        this.cd.detectChanges();
      }
    })

    this.onResize();
  }

  private onResize(): void {
    fromEvent<UIEvent>(window, 'resize')
      .pipe(untilDestroyed(this))
      .subscribe(() => {
        if (this.isNotPhone) {
          this.scrollbarIsVisible = this.scrollbar.state.isVerticallyScrollable;
          this.cd.markForCheck();
        }
      })

  }

  private initRouterListener() {
    this.router.events
      .pipe(
        filter((e): e is NavigationEnd => e instanceof NavigationEnd),
        untilDestroyed(this),
      )
      .subscribe(() => this.updateColorScheme());
  }

  private updateColorScheme() {
    const routeData = getStateTreeData(this.route.snapshot);
    this.productColorScheme = routeData.routeType === RouteTypeEnum.ProductPage;
  }
}
