import { CanvasTexture } from "three";
import {PlaneObject} from "../base/PlaneObject";

//@ts-ignore
import logo from "../../glsl/logo.frag.glsl";
import {SdfCanvas} from "../base/SdfCanvas";

const cnv = document.createElement("canvas");

export class Logo extends PlaneObject {

    constructor(logoSvg: string) {
        super(15, logo);
        this.svgTexture(logoSvg);
    }

    extendUniforms() {
        const texture = new CanvasTexture(cnv);
        this.uniforms.tex = {value: texture}
    }

    svgTexture(logo: string){
        let img = new Image();
        img.src = logo;
        img.onload = () => {
            const sdf = new SdfCanvas(img.width, img.height, 7, 0.0)
            sdf.drawImage(img)
            sdf.computeSdf();
            this.uniforms.tex.value = new CanvasTexture(sdf.canvas);
            this.uniforms.tex.value.anisotropy = 8;
            this.uniforms.tex.value.needsUpdate = true;
        };
    }
}
