import { Injectable } from '@angular/core';
import { AtomApp } from './ts/base/AtomApp';
import { Background, Electron, Link, Logo, Nucleus } from './ts/elements';
import { BehaviorSubject, fromEvent, merge, timer } from 'rxjs';
import { mapTo } from 'rxjs/operators';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

@Injectable()
@UntilDestroy()
export class AtomControlService {
  private atomApp: AtomApp;
  private background: Background;
  private electronMap: Record<string, Electron> = {};
  private animationStopper: () => void;
  public pointerDown$ = new BehaviorSubject(false);

  constructor() { }

  public initAtomApp(containerNode: HTMLElement) {
    const anchors = Array.from(containerNode.querySelectorAll('a'));
    merge(
      fromEvent(containerNode, 'pointerdown').pipe(mapTo(true)),
      fromEvent(containerNode, 'pointerup').pipe(mapTo(false)),
    )
      .pipe(untilDestroyed(this))
      .subscribe(this.pointerDown$);

    this.atomApp = new AtomApp(containerNode);
    this.background = new Background();
    this.atomApp.scene.add(this.background);
    this.atomApp.scene.add(new Nucleus());
    this.atomApp.scene.add(new Logo('assets/svg/rosatom-logo-vector.svg'));
    anchors.forEach((a: HTMLAnchorElement, i: number) => {
      const slug = a['dataset']['slug'];
      const electron = new Electron(i);
      electron.link = new Link(a);
      this.atomApp.electronsGroup.add(electron);
      this.electronMap[slug] = electron;
    });

    this.startAnimation();
  }

  public startAnimation() {
    this.animationStopper = this.atomApp.startRafLoop();
  }

  public stopAnimation() {
    this.animationStopper();
    this.animationStopper = undefined;
  }

  public goToMainScreen() {
    this.atomApp.controls.animationController.setTargetAnimationTime(0);
    return timer(0);
  }

  public goToElectronBySlug(slug: string) {
    this.goToElectron(this.electronMap[slug]);
    return timer(2000);
  }

  public flashIn() {
    this.background.animationController.setTargetAnimationTime(1);
    return timer(500);
  }

  public flashOut() {
    this.background.animationController.setTargetAnimationTime(0);
    return timer(500);
  }

  public setFlashIn() {
    this.background.animationController.targetAnimationTime = 1;
    this.background.animationController.currentAnimationTime = 1;
  }

  public setActiveElectronBySlug(slug: string) {
    this.atomApp.controls.activeElectron = this.electronMap[slug];
    this.atomApp.controls.animationController.targetAnimationTime = 1;
    this.atomApp.controls.animationController.currentAnimationTime = 1;
  }

  private goToElectron(electron: Electron) {
    this.atomApp.controls.activeElectron = electron;
    this.atomApp.controls.animationController.setTargetAnimationTime(1);
  }
}
