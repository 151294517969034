import { Component, HostBinding, Injector, Input } from '@angular/core';
import { Overlay, OverlayConfig, ScrollStrategyOptions } from '@angular/cdk/overlay';
import { ComponentPortal } from '@angular/cdk/portal';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { FeedbackFormPopupComponent } from '../feedback-form-popup/feedback-form-popup.component';
import { FEEDBACK_FORM_POPUP_DATA } from '../feedback-form-popup/feedback-form-popup-data.token';

@Component({
  selector: 'dp-feedback-button',
  templateUrl: './feedback-button.component.html',
  styleUrls: ['./feedback-button.component.styl']
})
@UntilDestroy()
export class FeedbackButtonComponent {
  @Input() icon: string;
  @Input() title: string;
  @Input() product: string;
  @Input() button: 'icon-only' | 'inline-text' | 'block-text';
  @Input() popup: 'main' | 'product';
  @HostBinding('class.feedback-button') componentClassName = true;
  @HostBinding('class.feedback-button_block') get isButtonBlock() { return this.button === 'block-text' };
  @HostBinding('class.feedback-button_main') get isButtonMain() { return this.popup === 'main' };
  @HostBinding('class.feedback-button_product') get isButtonProduct() { return this.popup === 'product' };

  constructor(
    private readonly overlay: Overlay,
    private readonly sso: ScrollStrategyOptions,
    private readonly injector: Injector,
  ) {
  }

  openDialog() {
    const popupOverlay =  this.overlay.create(this.getOverlayConfig());
    const burgerButtonPortal = new ComponentPortal(FeedbackFormPopupComponent, null, this.createInjector({
      title: this.title,
      icon: this.icon,
      type: this.title,
      product: this.product,
    }));
    const popupRef = popupOverlay.attach(burgerButtonPortal);
    const popup = popupRef.instance;

    popup.closeClicked
      .pipe(
        untilDestroyed(this),
      )
      .subscribe(() => popupOverlay.detach());

    popupOverlay.backdropClick()
      .pipe(
        untilDestroyed(this),
      )
      .subscribe(() => popup.close());

    setTimeout(() => {
      popupOverlay.updateScrollStrategy(this.overlay.scrollStrategies.block());
    }, 205);
  }

  createInjector(data: any): Injector {
    const providers = [{
      provide: FEEDBACK_FORM_POPUP_DATA,
      useValue: { ...data },
    }];
    return Injector.create({ providers, parent: this.injector });
  }

  private getOverlayConfig(): OverlayConfig {
    return new OverlayConfig({
      panelClass: ['dp-feedback-form-panel', `dp-feedback-form-panel_${this.popup}`],
      backdropClass: ['dp-feedback-form-overlay', `dp-feedback-form-overlay_${this.popup}`],
      hasBackdrop: true,
      scrollStrategy: this.overlay.scrollStrategies.block(),
      positionStrategy: this.overlay.position().global().centerHorizontally().centerVertically(),
    });
  }

}
