import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DpAtomComponent } from './components/dp-atom/dp-atom.component';
import { AtomControlService } from './services/atom-control.service';



@NgModule({
  declarations: [DpAtomComponent],
  exports: [DpAtomComponent],
  imports: [
    CommonModule
  ],
  providers: [AtomControlService],
})
export class DpAtomModule { }
