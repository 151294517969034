import { InjectionToken } from '@angular/core';
import { MatDialogConfig } from '@angular/material/dialog/dialog-config';

export interface PopupConfigInterface extends MatDialogConfig {
}

export const PopupConfigToken = new InjectionToken<PopupConfigInterface>('popupWindowConfig');

export const popupWindowConfig: PopupConfigInterface = {
  panelClass: 'dp-popup-window-panel',
  backdropClass: 'dp-popup-window-overlay',
  autoFocus: false,
  disableClose: true,
};
