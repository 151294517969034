import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  QueryList,
  ViewChild,
  ViewChildren
} from '@angular/core';
import { BreakpointObserver } from '@angular/cdk/layout';

import { timer } from 'rxjs';
import { debounceTime, distinctUntilChanged, filter, map } from 'rxjs/operators';
import { gsap, Power2 } from 'gsap';

import { BeforeLeaveComponent } from '@rosatom/ui-common';
import { AppDataService, CategoryListType } from '@rosatom/app-data';
import { AtomControlService } from '@rosatom/atom-app';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

@Component({
  selector: 'dp-main-page-stub',
  templateUrl: 'main-page-stub.component.html',
  styleUrls: ['main-page-stub.component.styl'],
})
@UntilDestroy()
export class MainPageStubComponent implements AfterViewInit, BeforeLeaveComponent  {
  @ViewChild('tooltipMainEllipse') tooltipMainEllipse: ElementRef<HTMLElement>;
  @ViewChild('tooltipMainWrap') tooltipMainWrap: ElementRef<HTMLElement>;
  @ViewChild('tooltipCore') tooltipCore: ElementRef<HTMLElement>;
  @ViewChildren('tooltipEllipse') tooltipEllipse = new QueryList<ElementRef<HTMLElement>>();
  @ViewChildren('tooltipArrow') tooltipArrow = new QueryList<ElementRef<HTMLElement>>();
  fadeOut = true;
  showAnimationCompleted = false;
  categoriesList: CategoryListType;
  hideTooltip = false;
  tlShowSphereTooltip = gsap.timeline({
    force3D: false,
  });
  tlSphereTooltip = gsap.timeline({
    force3D: false,
    repeat: -1,
    repeatDelay: 4,
  });

  isMobileDevice$ = this.breakpointObserver.observe('(max-width: 1279px)')
    .pipe(
      map(result => result.matches),
    );
  isNotPhone$ = this.breakpointObserver.observe('(min-width: 768px)')
    .pipe(
      map(result => result.matches),
    );

  constructor(
    private readonly breakpointObserver: BreakpointObserver,
    private readonly appDataService: AppDataService,
    private readonly atomControl: AtomControlService,
    private readonly cd: ChangeDetectorRef,
  ) {
    this.categoriesList = this.appDataService.appData.categoryList;
  }

  ngAfterViewInit() {
    this.initShowSphereTooltipAnimation();
    setTimeout(() => this.fadeOut = false);
    setTimeout(() => {
      this.tlShowSphereTooltip.reversed(false).resume();
    }, 500);
    this.atomControl.pointerDown$
      .pipe(
        filter(() => this.showAnimationCompleted),
        debounceTime(300),
        distinctUntilChanged(),
        untilDestroyed(this)
      )
      .subscribe(isPointerDown => {
        this.hideTooltip = isPointerDown;
        this.cd.detectChanges();
      });
  }

  beforeLeave(prev, next) {
    this.fadeOut = true;
    return timer(300).pipe(map(() => true));

  }

  initShowSphereTooltipAnimation(duration = 1.3): void {
    this.tlShowSphereTooltip
      .clear()
      .fromTo(this.tooltipCore.nativeElement, {
        scale: 10,
        opacity: 0,
      }, {
        scale: 1,
        opacity: 1,
        duration: duration,
        ease: Power2.easeInOut
      }, 0)
      .fromTo(this.tooltipEllipse.toArray()[0].nativeElement, {
        rotate: -180,
        scale: 1.3,
        opacity: 0,
      }, {
        rotate: 0,
        scale: 1,
        opacity: 1,
        duration: duration,
        ease: Power2.easeInOut
      }, 0)
      .fromTo(this.tooltipEllipse.toArray()[1].nativeElement, {
        rotate: 180,
        scale: 1.3,
        opacity: 0,
      }, {
        rotate: 90,
        scale: 1,
        opacity: 1,
        duration: duration,
        ease: Power2.easeInOut
      }, 0)
      .fromTo(this.tooltipArrow.map(item => item.nativeElement), {
        opacity: 0,
        y: (i) => {
          if (i === 0) {
            return -20;
          } else if (i === 2) {
            return 20;
          }
          return 0;
        },
        x: (i) => {
          if (i === 1) {
            return 20;
          } else if (i === 3) {
            return -20;
          }
          return 0;
        },
      }, {
        y: 0,
        x: 0,
        stagger: 0.08,
        opacity: 1,
        duration: duration,
        ease: Power2.easeInOut
      }, 0)
      .fromTo(this.tooltipMainEllipse.nativeElement, {
        scale: 0,
        opacity: 0,
      }, {
        scale: 1,
        opacity: 1,
        duration: duration,
        ease: Power2.easeInOut,
      }, 0)
      .add(() => {
        this.tlShowSphereTooltip.clear().invalidate();
        this.initSphereTooltipAnimation();
        this.showAnimationCompleted = true;
      })
      .pause();
  }

  initSphereTooltipAnimation(): void {
    this.tlSphereTooltip
      .clear()
      .to(this.tooltipMainEllipse.nativeElement, {
        scaleX: 1.3,
        duration: 0.5,
        ease: Power2.easeInOut
      }, 0)
      .to(this.tooltipArrow.map(item => item.nativeElement), {
        y: (i) => {
          if (i === 0) {
            return -10;
          } else if (i === 2) {
            return 10;
          }
          return 0;
        },
        x: (i) => {
          if (i === 1) {
            return 10;
          } else if (i === 3) {
            return -10;
          }
          return 0;
        },
        duration: 0.5,
        ease: Power2.easeInOut
      }, 0)
      .to(this.tooltipMainWrap.nativeElement, {
        rotate: '+=360',
        duration: 2,
        ease: Power2.easeInOut,
      }, 0)
      .to(this.tooltipEllipse.map(item => item.nativeElement), {
        rotate: '-=360',
        duration: 2,
        ease: Power2.easeInOut,
      }, 0)
      .to(this.tooltipMainEllipse.nativeElement, {
        scaleX: 1,
        duration: 0.5,
        ease: Power2.easeInOut
      }, 2)
      .to(this.tooltipArrow.map(item => item.nativeElement), {
        y: 0,
        x: 0,
        duration: 0.5,
        ease: Power2.easeInOut
      }, 2)
      .to(this.tooltipMainEllipse.nativeElement, {
        scaleX: 1.3,
        duration: 0.5,
        ease: Power2.easeInOut
      }, 6)
      .to(this.tooltipArrow.map(item => item.nativeElement), {
        y: (i) => {
          if (i === 0) {
            return -10;
          } else if (i === 2) {
            return 10;
          }
          return 0;
        },
        x: (i) => {
          if (i === 1) {
            return 10;
          } else if (i === 3) {
            return -10;
          }
          return 0;
        },
        duration: 0.5,
        ease: Power2.easeInOut
      }, 6)

      .to(this.tooltipEllipse.map(item => item.nativeElement)[0], {
        scale: 1.2,
        duration: 0.5,
        ease: Power2.easeInOut,
      }, 6.5)

      .to(this.tooltipEllipse.map(item => item.nativeElement)[0], {
        x: -10,
        duration: 0.5,
        ease: Power2.easeInOut,
      }, 7)
      .to(this.tooltipArrow.map(item => item.nativeElement)[1], {
        x: '-=10',
        duration: 0.5,
        ease: Power2.easeInOut,
      }, 7)
      .to(this.tooltipArrow.map(item => item.nativeElement)[3], {
        x: '-=10',
        duration: 0.5,
        ease: Power2.easeInOut,
      }, 7)

      .to(this.tooltipEllipse.map(item => item.nativeElement)[0], {
        x: 10,
        duration: 0.5,
        ease: Power2.easeInOut,
      }, 7.5)
      .to(this.tooltipArrow.map(item => item.nativeElement)[1], {
        x: '+=20',
        duration: 0.5,
        ease: Power2.easeInOut,
      }, 7.5)
      .to(this.tooltipArrow.map(item => item.nativeElement)[3], {
        x: '+=20',
        duration: 0.5,
        ease: Power2.easeInOut,
      }, 7.5)

      .to(this.tooltipEllipse.map(item => item.nativeElement)[0], {
        x: 0,
        scale: 1,
        duration: 0.5,
        ease: Power2.easeInOut,
      }, 8)
      .to(this.tooltipArrow.map(item => item.nativeElement)[1], {
        x: '-=10',
        duration: 0.5,
        ease: Power2.easeInOut,
      }, 8)
      .to(this.tooltipArrow.map(item => item.nativeElement)[3], {
        x: '-=10',
        duration: 0.5,
        ease: Power2.easeInOut,
      }, 8)

      .to(this.tooltipEllipse.map(item => item.nativeElement)[1], {
        scale: 1.2,
        duration: 0.5,
        ease: Power2.easeInOut,
      }, 8.5)

      .to(this.tooltipEllipse.map(item => item.nativeElement)[1], {
        y: -10,
        duration: 0.5,
        ease: Power2.easeInOut,
      }, 9)
      .to(this.tooltipArrow.map(item => item.nativeElement)[0], {
        y: '-=10',
        duration: 0.5,
        ease: Power2.easeInOut,
      }, 9)
      .to(this.tooltipArrow.map(item => item.nativeElement)[2], {
        y: '-=10',
        duration: 0.5,
        ease: Power2.easeInOut,
      }, 9)

      .to(this.tooltipEllipse.map(item => item.nativeElement)[1], {
        y: 10,
        duration: 0.5,
        ease: Power2.easeInOut,
      }, 9.5)
      .to(this.tooltipArrow.map(item => item.nativeElement)[0], {
        y: '+=20',
        duration: 0.5,
        ease: Power2.easeInOut,
      }, 9.5)
      .to(this.tooltipArrow.map(item => item.nativeElement)[2], {
        y: '+=20',
        duration: 0.5,
        ease: Power2.easeInOut,
      }, 9.5)

      .to(this.tooltipEllipse.map(item => item.nativeElement)[1], {
        y: 0,
        scale: 1,
        duration: 0.5,
        ease: Power2.easeInOut,
      }, 10)
      .to(this.tooltipArrow.map(item => item.nativeElement)[0], {
        y: '-=10',
        duration: 0.5,
        ease: Power2.easeInOut,
      }, 10)
      .to(this.tooltipArrow.map(item => item.nativeElement)[2], {
        y: '-=10',
        duration: 0.5,
        ease: Power2.easeInOut,
      }, 10)

      .to(this.tooltipMainEllipse.nativeElement, {
        scaleX: 1,
        duration: 0.5,
        ease: Power2.easeInOut
      }, 10.5)
      .to(this.tooltipArrow.map(item => item.nativeElement), {
        y: 0,
        x: 0,
        duration: 0.5,
        ease: Power2.easeInOut
      }, 10.5)
  }
}
