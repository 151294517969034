<div class="dp-feedback-form__header">
  <div class="dp-feedback-form__title">
    <mat-icon class="dp-feedback-form__title-icon" [svgIcon]="icon"></mat-icon>

    <span [innerHTML]="title | stripHtml:'br':'span'"></span>
  </div>

  <button class="dp-feedback-form__close-button" (click)="close()">
    <mat-icon class="dp-feedback-form__close-icon" svgIcon="close"></mat-icon>
  </button>
</div>

<div class="dp-feedback-form__content-wrapper">
  <dp-feedback-form
    [type]="type"
    [product]="product"
    [colorScheme]="!!product ? 'product' : 'main'"
    (success)="close()"
  >
  </dp-feedback-form>
</div>
