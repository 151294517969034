<button
  *ngIf="showHome"
  class="back-button__wrapper back-button__wrapper_home"
  [routerLink]="['/']"
>
  <span class="back-button__icon-wrapper">
    <mat-icon
      class="back-button__icon"
      svgIcon="home"
      aria-hidden="true"
    >
    </mat-icon>
  </span>
  <span *ngIf="!categorySlug && !productSlug" class="back-button__text">{{ title | stripHtml }}</span>
</button>

<button
  *ngIf="!!categorySlug || !!productSlug"
  class="back-button__wrapper"
  [routerLink]="categorySlug ? ['/category', categorySlug] : ['/product', productSlug]"
>
  <span class="back-button__icon-wrapper">
    <mat-icon
      class="back-button__icon"
      svgIcon="back"
      aria-hidden="true"
    >
    </mat-icon>
  </span>
  <span
    *ngIf="text"
    class="back-button__text"
    [innerHTML]="text | stripHtml:'br':'span' | typograph"
    [title]="text | stripHtml "
  ></span>
</button>
