import {PlaneObject} from "../base/PlaneObject";

//@ts-ignore
import glow from "../../glsl/glow.frag.glsl";

export class GlowingDot extends PlaneObject {

    constructor(size: number, dx: number) {
        super(size, glow);
        this.position.x = dx;
    }
}