<dp-atom>
  <a
    class="atom-link"
    *ngFor="let category of categoriesList"
    [innerHTML]="category.title | stripHtml:'br':'span'"
    [attr.data-slug]="category.slug"
    [routerLink]="['/category', category.slug]"
    (touchstart)="onTouchstart($event)"
    (touchend)="goToCategory(category.slug, $event)"
  >
  </a>
</dp-atom>
