<ng-container *ngIf="isNotPhone$ | async; else mobileContent" [ngTemplateOutlet]="desktopContent"></ng-container>

<ng-template #mobileContent>
  <div class="main-menu__wrapper">
    <div class="main-menu__categories-list" cdkScrollable>
      <div
        class="main-menu__category"
        *ngFor="let category of categoriesList;
              let i = index"
      >
        <a
          class="main-menu__category-title"
          [innerHTML]="category.title | stripHtml:'br':'span' | typograph"
          [routerLink]="['/category', category.slug]"
          (click)="closeMenu.emit()"
        >
        </a>

        <ul class="main-menu__category-products product-list">
          <li
            class="product-list__item"
            *ngFor="let product of category.products"
          >
            <a
              class="product-list__link"
              [innerHTML]="product.title | stripHtml:'br':'span' | typograph"
              [routerLink]="['/product', product.slug]"
              (click)="closeMenu.emit()"
            >
            </a>
          </li>
        </ul>
      </div>
    </div>
  </div>
</ng-template>


<ng-template #desktopContent>
  <ng-scrollbar
    #scrollbar
    class="main-menu__scroll-wrapper"
    [trackClass]="productColorScheme ? 'dp-scrollbar-track__menu_product' : 'dp-scrollbar-track__menu'"
    [thumbClass]="productColorScheme ? 'dp-scrollbar-thumb__menu_product' : 'dp-scrollbar-thumb__menu'"
  >
    <div class='main-menu__scroll-inner'>
      <div class='main-menu__scroll-vertical'
           *ngIf='scrollbarIsVisible'
           [class.main-menu__scroll-vertical_product]='productColorScheme'

      ></div>

      <div class="main-menu__wrapper">
        <div class="main-menu__categories-list" cdkScrollable>
          <div
            class="main-menu__category"
            *ngFor="let category of categoriesList;
              let i = index"
          >
            <a
              class="main-menu__category-title"
              [innerHTML]="category.title | stripHtml:'br':'span' | typograph"
              [routerLink]="['/category', category.slug]"
              (click)="closeMenu.emit()"
            >
            </a>

            <ul class="main-menu__category-products product-list">
              <li
                class="product-list__item"
                *ngFor="let product of category.products"
              >
                <a
                  class="product-list__link"
                  [innerHTML]="product.title | stripHtml:'br':'span' | typograph"
                  [routerLink]="['/product', product.slug]"
                  (click)="closeMenu.emit()"
                >
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>


    </div>
  </ng-scrollbar>
</ng-template>


