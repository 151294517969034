<dp-feedback-button
  *ngIf="showButton && (isNotPhone$ | async)"
  [title]="staticText['CONTACT_US_FORM_TITLE']"
  class="feedback-button"
  button="block-text"
  popup="main"
  icon="contact-us"
>
  <span [innerHTML]="staticText['BUTTON_CONTACT_US'] | stripHtml:'br'"></span>
</dp-feedback-button>
